// import { HttpError } from '@refinedev/core';
import axios from 'axios';
import { API_KEY, API_URL, bundle_id } from '../../configs';
import { ConfigsStore } from '../../Store';

const axiosInstance = axios.create({
  baseURL: API_URL, // Replace with your API URL
});
axiosInstance.interceptors.request.use(
  async (config) => {
    {
      const token = localStorage.getItem('access_token');
      if (token) {
        {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      config.headers['API_KEY'] = API_KEY;
      config.headers['bundle_id'] = bundle_id;
      config.headers['device_id'] = await ConfigsStore.get('device_id');

      return config;
    }
  },
  (error) => {
    {
      return Promise.reject(error);
    }
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error.response && error.response.status === 403) ||
      error.response.status === 401
    ) {
      localStorage.removeItem('refine-auth');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);
export { axiosInstance };
