import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { DateRangePicker } from 'react-date-range';
// import { datePickerButtonStyles } from './styles';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';
import { Calendear } from '../../Assets/Svgs';
export const DatePickerPopUp = ({
  onDateChange,
}: {
  onDateChange: (dates: Date[]) => void;
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const [, setTempState] = useState(state);
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    // Reset date range to previous state
    setTempState(state);
    setVisible(false);
  };

  const handleApply = () => {
    // Apply temporary changes to the date range
    setVisible(false);

    if (state.length > 0) {
      const dates = [state[0].startDate, state[0].endDate];
      onDateChange(dates);
    }
  };

  const content = (
    <div className="flex flex-col overflow-x-scroll">
      <DateRangePicker
        onChange={(item: any) => setState([item.selection])}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        startDatePlaceholder="From-"
      />
      <div className="flex justify-end p-4">
        <div style={{ display: 'flex' }} className="gap-x-3">
          <Button onClick={handleCancel} className="px-3 py-3 text-[#344054]">
            Cancel
          </Button>
          <Button
            className="bg-primary text-white px-3 py-3"
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      placement="bottomRight"
      content={content}
      trigger="click"
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ zIndex: 999 }}
      style={{ display: 'flex', alignItems: 'center' }}
      arrow={false}
      open={visible}
      onOpenChange={setVisible}
    >
      <Button>
        <Calendear /> {state[0].startDate.toLocaleDateString()} -
        {state[0].endDate.toLocaleDateString()}
      </Button>
    </Popover>
  );
};
