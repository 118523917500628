import React, { useState } from 'react';
import {
  Modal,
  Select,
  Upload,
  Input,
  Button,
  Checkbox,
  Tooltip,
  message,
} from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { CloseIcon, TickCircleIcon } from '../../Assets/Svgs';
import { DoctorRes } from 'src/types';
import { DoctorPointsManagement } from '../../Actions/ConfigActions';
import * as XLSX from 'xlsx';

import { axiosInstance } from '../../rest-data-provider/utils';
import { useSelect } from '@refinedev/antd';
interface BulkPointsManagementModalProps {
  visible: boolean;
  onClose: () => void;
  doctors: DoctorRes[] | undefined;
}

interface TagRenderProps {
  value: string;
  closable: boolean;
  onClose: () => void;
}

const BulkPointsManagementModal: React.FC<BulkPointsManagementModalProps> = ({
  visible,
  onClose,
  doctors,
}) => {
  const { selectProps } = useSelect<DoctorRes>({
    resource: 'account-cruds',
    //@ts-ignore
    optionLabel: (item: {
      name: string;
      phone_number: string;
      id: string;
    }): string => {
      return `${item.name} ${item.phone_number} (${item.id})`;
    },
    optionValue: 'id',
    onSearch: (value) => [
      {
        field: 'search_field',
        operator: 'eq',
        value,
      },
    ],
  });

  const [selectedDoctors, setSelectedDoctors] = useState<string[]>([]);
  const [selectedMultiplier, setSelectedMultiplier] = useState<number>(0);
  const [selectedMultiplierLabel, setSelectedMultiplierLabel] =
    useState<string>('');
  const [fileList, setFileList] = useState<any[]>([]);
  const [disableSearch, setDisableSearch] = useState<boolean>(false);
  const [disableUpload, setDisableUpload] = useState<boolean>(false);
  const [successModalVisible, setSuccessModalVisible] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [successDetails, setSuccessDetails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [selectedPhones, setSelectedPhones] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);

  const handleDoctorSelect = (value: string[]) => {
    setSelectedDoctors(value);
    setDisableUpload(value.length > 0);
  };

  const handleFileChange = async ({ fileList }: any) => {
    setFileList(fileList);
    setDisableSearch(fileList.length > 0);
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as string[][];
      const phoneNumbers = rows.map((row) => row[0]);
      setSuccessDetails(phoneNumbers);
    }
    setFile(fileList[0]?.originFileObj);
  };

  const handleFileRemove = () => {
    setFileList([]);
    setDisableSearch(false);
  };

  const handleAdd = async () => {
    setLoading(true); // Start loading
    if (selectedDoctors.length > 0 && selectedMultiplier > 0) {
      // Add doctors logic
      const selectedPhones = selectedDoctors.map((id) => {
        const doctor = doctors?.find((doc) => doc.id === parseInt(id, 10));
        return doctor ? doctor?.phone_number : '';
      });
      setLoading(true);
      setSelectedPhones(selectedPhones);
      try {
        await DoctorPointsManagement({
          ids: selectedDoctors.map((id) => parseInt(id, 10)),
          factor_points: selectedMultiplier,
        });
        onClose();
        showSuccessModal(
          `The following numbers have been added successfully`,
          selectedPhones
        );
        setLoading(true);
      } catch (error) {
        message.error('Something went wrong', 3);
        setLoading(false);
      }

      onClose();
      try {
        await DoctorPointsManagement({
          ids: selectedDoctors.map((id) => parseInt(id, 10)),
          factor_points: selectedMultiplier,
        });
        showSuccessModal(
          `${selectedPhones.length} numbers have been added successfully`,
          selectedPhones
        );
        setLoading(false);
      } catch (error) {
        message.error('Something went wrong', 3);
        setLoading(false);
      }

      onClose();
    } else if (fileList.length > 0) {
      // Add file logic
      setLoading(true);
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('factor_points', selectedMultiplier.toString());

        try {
          await axiosInstance.post(
            'account-cruds/update-factor-points',
            formData
          );
          setLoading(false);
          message.success('File uploaded successfully', 3);
          showSuccessModal(
            `${successDetails.length} Numbers have been added successfully`,
            [fileList[0].name]
          );
          setFile(null);
        } catch (error) {
          message.error('Something went wrong', 3);
          setFile(null);
          setLoading(false);
        }
      }
      onClose();
    }
    resetForm();
    setLoading(false); // Stop loading
  };

  const showSuccessModal = (message: string, details: string[]) => {
    setSuccessMessage(message);
    setSuccessDetails(details);
    setSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalVisible(false);
  };

  const resetForm = () => {
    setSelectedDoctors([]);
    setSelectedMultiplier(0);
    setFileList([]);
    setDisableSearch(false);
    setDisableUpload(false);
  };

  const isAddDisabled =
    (selectedDoctors.length === 0 || fileList.length === 0) &&
    selectedMultiplier > 0;

  const tagRender = ({ value, closable, onClose }: TagRenderProps) => {
    const doctor = doctors?.find((doc) => doc.id.toString() === value);
    return (
      <span className="ant-select-selection-item flex items-center">
        {closable && <CloseCircleOutlined onClick={onClose} />}
        <span className="rounded-lg px-2 py-1 text-neutral90">
          {doctor?.phone_number}
        </span>
      </span>
    );
  };

  const handleSelectMultiplier = (value: number, option: any) => {
    setSelectedMultiplier(value);
    setSelectedMultiplierLabel(option.children);
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        centered
        width={498}
        closeIcon={
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        }
        title={
          <span className="font-semibold text-base mb-6 text-neutral90">
            Enter Mobile numbers to add to Multi Points list
          </span>
        }
        footer={null}
        styles={{ content: { borderRadius: '24px' } }}
      >
        <div className="flex flex-col gap-y-6 mt-6">
          <div className="flex flex-col custome-selection">
            <span className="font-medium text-xs text-gray400 mb-1">
              Search doctors
            </span>
            <Tooltip
              title={
                disableSearch
                  ? "You can select a doctor's number or file but not both"
                  : ''
              }
            >
              <Select
                mode="multiple"
                size="large"
                className="custom-select"
                placeholder={
                  <span className="font-medium text-sm text-gray400 ">
                    Enter mobile number, doctor’s ID or name
                  </span>
                }
                // @ts-ignore
                value={selectedDoctors.map(String)}
                // onSearch={handleSearchChange}
                // @ts-ignore
                onChange={handleDoctorSelect}
                filterOption={false}
                tagRender={tagRender}
                dropdownRender={() => (
                  <div className="w-[100%] max-h-[200px] overflow-y-auto">
                    <div>
                      {selectProps.options?.map((doctor) => (
                        <div
                          key={doctor.value}
                          className={`flex p-2 items-center justify-between space-x-3 gap-x-8 gap-y-1 ${
                            selectedDoctors.includes(String(doctor.value))
                              ? 'bg-[#ff0006] bg-opacity-10'
                              : ''
                          }`}
                        >
                          <Checkbox
                            checked={selectedDoctors.includes(
                              String(doctor.value)
                            )}
                            onChange={() =>
                              handleDoctorSelect(
                                selectedDoctors.includes(String(doctor.value))
                                  ? selectedDoctors.filter(
                                      (id) => id !== String(doctor.value)
                                    )
                                  : [...selectedDoctors, String(doctor.value)]
                              )
                            }
                            className="flex items-center"
                          >
                            <span className="text-sm font-normal text-neutral90 space-x-1">
                              <span className="inline-block w-1  rounded-full"></span>
                              <span className="text-neutral90 px-1">
                                {doctor.label}
                              </span>
                            </span>
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                    {/* )} */}
                  </div>
                )}
                getPopupContainer={(trigger) =>
                  trigger.parentElement as HTMLElement
                }
                {...selectProps}
                disabled={disableSearch}
              />
            </Tooltip>
          </div>
          <div>
            <span className="font-medium text-xs text-gray400 mb-1">
              Or upload file
            </span>
            <Tooltip
              title={disableUpload ? 'You can select a doctor or file' : ''}
            >
              <Upload
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={() => false}
                disabled={disableUpload}
                className={`w-full ${disableUpload ? 'pointer-events-none opacity-50' : ''}`}
                accept=".xlsx,.xls"
              >
                <Input
                  className={`border rounded-lg px-3 py-4 bg-[#F5F5F5] ${
                    disableUpload ? 'pointer-events-none opacity-50' : ''
                  }`}
                  placeholder="Browse Files"
                  value={fileList.length > 0 ? fileList[0].name : ''}
                  suffix={
                    fileList.length > 0 ? (
                      <DeleteOutlined onClick={handleFileRemove} />
                    ) : (
                      <UploadOutlined />
                    )
                  }
                  readOnly
                />
              </Upload>
            </Tooltip>
          </div>
          <div>
            <Select
              style={{ width: '100%' }}
              size="large"
              placeholder={
                <span className="text-neutral200 text-sm font-normal leading-[22px]">
                  Select multiplier
                </span>
              }
              onSelect={handleSelectMultiplier}
            >
              {[
                { label: '@x0.25', value: 0.25 },
                { label: '@x0.5', value: 0.5 },
                { label: '@x0.75', value: 0.75 },
                { label: '@x1', value: 1 },
                { label: '@x1.25', value: 1.25 },
                { label: '@x1.5', value: 1.5 },
                { label: '@x1.75', value: 1.75 },
                { label: '@x2', value: 2 },
              ].map((multiplier) => (
                <Select.Option key={multiplier.value} value={multiplier.value}>
                  {multiplier.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <Button
              type="primary"
              onClick={handleAdd}
              loading={loading} // Loading state
              disabled={!isAddDisabled}
              className="w-full font-semibold text-base text-white px-4 py-6 rounded-xl"
            >
              Add
            </Button>
            <button
              onClick={onClose}
              className="w-full font-semibold text-base text-[#848484] mt-6"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={successModalVisible}
        onCancel={handleCloseSuccessModal}
        centered
        width={400}
        footer={null}
        title={
          <div className="flex items-center justify-center mt-4">
            <TickCircleIcon style={{ width: '72px', height: '72px' }} />
          </div>
        }
      >
        <div className="flex flex-col items-center">
          <p className="text-base mt-4 text-center text-wrap">
            {successMessage}
          </p>
          <div className="flex flex-wrap mt-4">
            {successDetails.map((detail, index) => (
              <span
                key={index}
                className={
                  selectedPhones.includes(detail)
                    ? 'bg-gray-200 rounded-[30px] px-2 py-1 mr-2 my-1 text-neutral90'
                    : 'items-start ml-[-40%] my-1 text-primary'
                }
              >
                {detail}
              </span>
            ))}
          </div>
          <p className="text-base font-normal text-neutral90 mt-4">
            Points will be multiplied by {selectedMultiplierLabel}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default BulkPointsManagementModal;
