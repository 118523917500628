import React, { useEffect } from 'react';
import { Refine } from '@refinedev/core';
import { DevtoolsPanel } from '@refinedev/devtools';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import { useNotificationProvider } from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { App as AntdApp } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { authProvider } from './authProvider';
import { ColorModeContextProvider } from './contexts/color-mode';
import Stack from './Resources';
import { dataProvider } from './rest-data-provider';
import { API_URL } from './configs';
import './index.css';
import { CreateDeviceAction } from './Actions/AuthActions';
import { AxiosInstance } from 'axios';
import { resources } from './Resources/resources';
import { axiosInstance } from './rest-data-provider/utils/axios';
import { customTitleHandler } from './Helpers/inedx';
function App() {
  useEffect(() => {
    CreateDeviceAction();
  }, []);
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <AntdApp>
            <Refine
              dataProvider={dataProvider(
                API_URL,
                axiosInstance as AxiosInstance
              )}
              notificationProvider={useNotificationProvider}
              routerProvider={routerBindings}
              resources={resources}
              authProvider={authProvider}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                useNewQueryKeys: true,
                projectId: 'bzmkSC-KJooXF-ea64XL',
              }}
            >
              <Stack />
              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler handler={customTitleHandler} />
            </Refine>
            <DevtoolsPanel />
          </AntdApp>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
