import React, { useEffect, useRef, useState } from 'react';
import { Text } from '../../text';
import { Button, Popover, Space } from 'antd';
import { NotificationMessage } from './notification-message';
import { NotificationResponse } from '../../../types';
import { BellOutlined, LoadingOutlined } from '@ant-design/icons';
import { getNotifications } from '../../../Actions/ConfigActions';

// Import statements

export const Notifications: React.FC = () => {
  const [notification, setNotification] = useState<NotificationResponse[]>([]);
  // useEffect(() => {
  //   const handleImageBinary = async () => {
  //     const response = await getNotifications();
  //     console.log(response);
  //     setNotification(response.data);
  //   };
  //   if (openNotification) {
  //     handleImageBinary();
  //   }
  // }, [openNotification]);

  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      const fetchData = async () => {
        try {
          setLoadingContent(true);
          const data = await getNotifications();
          setNotification(data.data);
        } catch (error) {
          setNotification([]); // Handle error gracefully
        } finally {
          setLoadingContent(false);
        }
      };
      fetchData();
    }

    return () => {};
  }, []);

  const renderNotifications = () => {
    if (loadingContent) {
      return <LoadingOutlined />;
    } else if (notification.length > 0) {
      return notification.map((message) => (
        <Space
          key={message.id}
          style={{
            // padding: '8px 0',
            width: '100%',
          }}
          className="py-2.5 px-[13px] hover:bg-grayLight"
        >
          <NotificationMessage message={message} />
        </Space>
      ));
    } else {
      return <div className="ps-3">No notifications</div>;
    }
  };

  const content = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Text className="font-semibold text-neutral70 text-sm py-1 px-3">
        Notifications
      </Text>
      {renderNotifications()}
    </Space>
  );

  return (
    <Popover
      placement="bottomRight"
      content={content}
      trigger="click"
      overlayStyle={{ width: '16.3125rem' }}
      overlayInnerStyle={{ padding: '8px 0px', gap: '8px' }}
      arrow={false}
    >
      <Button
        shape="default"
        icon={
          <div style={{ position: 'relative' }}>
            <BellOutlined />
            {notification.length > 0 && (
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  background: 'red',
                  display: 'block',
                }}
              />
            )}
          </div>
        }
        style={{
          border: 0,
          width: '100%',
          textAlign: 'center',
          padding: '10px 12px',
          gap: '0.5rem',
          backgroundColor: '#F8F8F8',
          height: '2.625rem',
        }}
      />
    </Popover>
  );
};
