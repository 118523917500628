import { ShowButton } from '@refinedev/antd';
import { NotificationResponse } from '../../../types';
import { Text } from '../../text';

type Props = {
  message?: NotificationResponse;
};

export const NotificationMessage = ({ message }: Props) => {
  if (!message) return <Text>Loading...</Text>;

  return (
    <div>
      <Text
        style={{
          textAlign: 'right',
        }}
        className={`text-neutral90 font-normal ${message.params.type !== 'new_contact_us' && 'cursor-pointer  rounded'}  `}
      >
        {message.body || 'Unassigned'}
        {message.params.type !== 'new_contact_us' && (
          <ShowButton
            size="small"
            resource="account-cruds"
            recordItemId={message.params.id}
          />
        )}
      </Text>
    </div>
  );
};
