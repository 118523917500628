import React, { useState } from 'react';
import { List, useTable } from '@refinedev/antd';
import { Button, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { ColHeaderLeft } from '@components/ColHeaderLeft';
import { DoctorRes } from '../../types';
import { formatDate } from '../../Helpers/inedx';
import { AddMemberIcon, BackIcon, DeleteIcon } from '../../Assets/Svgs';
import { useNavigation } from '@refinedev/core';
import DeleteDoctortModal from '@components/DeleteModal';
import AddMemberModal from '@components/AddMemberModal';

const SharedWallet = () => {
  const [open, setModalOpen] = useState(false);
  const [AddMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [memberID, setMemberID] = useState<number>(0);
  const { show } = useNavigation();
  const location = useLocation();
  const { data } = location.state || {};
  const doctorData: DoctorRes = data;
  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable({
    resource: `shared-wallet`,
    filters: {
      initial: [{ field: 'account_id', operator: 'eq', value: doctorData?.id }],
    },
    syncWithLocation: false,
  });
  const navigateToShowPage = () => {
    const resource = 'account-cruds'; // Replace with your resource name
    const id = doctorData?.id;
    show(resource, id); // It navigates to the `/posts/show/1` page
  };
  const updateModalOpen = (value: boolean) => {
    setModalOpen(value);
  };
  const updateAddMemberModalOpen = (value: boolean) => {
    setAddMemberModalOpen(value);
  };
  return (
    <List
      title={
        <div className="flex gap-x-2 md:gap-x-8 md:mt-12 md:mb-9">
          <Button
            icon={<BackIcon />}
            onClick={navigateToShowPage}
            className="text-neutral70 font-semibold bg-transparent border-none shadow-none p-0"
          >
            Back
          </Button>
          <div className="flex gap-x-2 md:gap-x-6">
            <div className="text-neutral90 text-xl">Dr. {doctorData?.name}</div>
            <div className="text-gray500 text-2xl">Shared Wallet</div>
          </div>
        </div>
      }
      headerButtons={
        <div className="flex md:mt-12 md:mb-9 gap-x-6">
          <div className="flex items-center text-gray600 text-xs font-light">
            Max: 4 members
          </div>
          <Button
            icon={
              <AddMemberIcon
                fill={(tableProps.dataSource?.length ?? 0) < 4 ? '#C01E2E' : ''}
              />
            }
            className={`${tableProps?.dataSource && tableProps?.dataSource?.length < 4 ? 'pointer' : 'cursor-not-allowed'} ${tableProps?.dataSource && tableProps?.dataSource?.length < 4 ? 'text-primary' : 'text-gray200'}  font-semibold bg-transparent border-none shadow-none`}
            onClick={() => updateAddMemberModalOpen(true)}
            disabled={tableProps.dataSource && tableProps.dataSource.length > 3}
          >
            Add Member
          </Button>
        </div>
      }
    >
      <Table
        {...tableProps}
        className="w-full border border-gray-300 rounded-lg"
        onHeaderRow={() => ({ className: 'table-header' })}
        rowKey="id"
        bordered={false}
        pagination={{
          position: ['bottomCenter'],
          nextIcon: false,
          prevIcon: false,
          pageSize: 9,
        }}
        style={{
          padding: 0,
          overflowX: 'scroll',
          paddingBlockStart: '0 !important',
        }}
      >
        <Table.Column
          className="table-header"
          dataIndex="name"
          title={<ColHeaderLeft title="Member Name" />}
          render={(value) => (
            <span className="text-neutral90 text-sm	 font-normal">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="phone"
          title={<ColHeaderLeft title="Mobile number" />}
          render={(value) => (
            <span className="text-neutral90 text-sm	 font-normal">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="created_at"
          title={<ColHeaderLeft title="Date Added" />}
          render={(value) => (
            <span className="text-neutral90 text-sm	 font-normal">
              {formatDate(value)}
            </span>
          )}
        />
        <Table.Column
          className="table-header"
          title={'Delete'}
          render={(record) => (
            <span className="flex justify-center  font-normal text-gray500">
              <DeleteIcon
                onClick={() => {
                  setMemberID(record?.id);
                  setModalOpen(true);
                }}
              />
            </span>
          )}
        />
      </Table>
      <DeleteDoctortModal
        open={open}
        updateModalOpen={updateModalOpen}
        id={memberID}
        title="Delete Member?"
        header="Are you sure you want to delete this member?"
        memberDelete
        refetch={refetch}
      />
      <AddMemberModal
        open={AddMemberModalOpen}
        updateModalOpen={updateAddMemberModalOpen}
        account_id={doctorData?.id}
        refetch={refetch}
      />
    </List>
  );
};

export default SharedWallet;
