import { HomeData } from 'src/types';
import { axiosInstance } from '../rest-data-provider/utils/axios';
export const updateActiveUser = async ({ id }: { id: number }) => {
  try {
    const response = await axiosInstance.patch(
      `/account-cruds/deactivate-account/${id}`
    );
    return response.data; // Return the data if needed
  } catch (error) {
    throw new Error('Error');
  }
};
export const addWalletMember = async ({
  name,
  phone,
  account_id,
  setLoading,
}: {
  name: string;
  phone: string;
  account_id: number;
  setLoading: (value: boolean) => void;
}) => {
  setLoading(true);
  try {
    const response = await axiosInstance.post(`/shared-wallet`, {
      name,
      phone,
      account_id,
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return error;
  }
};
export const DoctorPointsManagement = async ({
  ids,
  factor_points,
  redemption_rate,
  add_points,
}: {
  ids: number[];
  factor_points: number;
  redemption_rate?: number;
  add_points?: number;
}) => {
  try {
    const body = {
      ids,
      factor_points,
      redemption_rate,
      add_points,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await axiosInstance.post(
      `/account-cruds/double-Points/`,
      body,
      {
        headers,
      }
    );
    return response.data; // Return the data if needed
  } catch (error: any) {
    throw new Error('Error');
  }
};
export const updateDoctorDetails = async ({
  id,
  name,
  email,
  phone_number,
  file,
}: {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  file?: string;
}) => {
  try {
    const body = {
      name,
      email,
      phone_number,
      file,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axiosInstance.patch(`/user-profile/${id}`, body, {
      headers,
    });

    return response.data; // Return the data if needed
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
export const getDoctorImage = async ({ id }: { id: number }) => {
  try {
    const response = await axiosInstance.get(`/upload/url/${id}`);

    return response; // Return the data if needed
  } catch (error) {
    throw new Error('Error');
  }
};

export const deleteDoctorImage = async ({ id }: { id: number }) => {
  try {
    const response = await axiosInstance.delete(`/upload/${id}`);

    return response; // Return the data if needed
  } catch (error) {
    throw new Error('Error');
  }
};

export const GetGraphDataAction = async (
  fromDate?: string,
  toDate?: string
) => {
  try {
    const data = await axiosInstance<HomeData>({
      url: '/account-cruds/graph-data-idh',
      params: { fromDate: fromDate, toDate: toDate },
    });
    return data.data;
  } catch (error) {
    return error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await axiosInstance.get(
      `/notification-cruds/unread-notifications`
    );

    return response; // Return the data if needed
  } catch (error) {
    throw new Error('Error');
  }
};
