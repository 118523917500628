import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { BackIcon, CloseIcon } from '../../Assets/Svgs';
import { getDoctorImage } from '../../Actions/ConfigActions';

interface Props {
  open?: boolean;
  updateModalOpen?: (value: boolean) => void;
  updateImageModalOpen?: (value: boolean) => void;
  id?: number;
  imageSrc?: string | null | undefined;
  name?: string | undefined;
  initialImageSrc?: string | null | undefined;
  setInitialImageSrc?: (value: string) => void;
  setInitialImageSrcList?: (value: string) => void;
  imageId?: any;
  ImageModalOpen?: boolean;
}

const DoctorImageModal: React.FC<Props> = ({
  open,
  updateImageModalOpen,
  imageId,
  imageSrc,
  initialImageSrc,
  name,
  setInitialImageSrc,
  updateModalOpen,
  setInitialImageSrcList,
  ImageModalOpen,
}: Props) => {
  const handleCancel = () => {
    updateModalOpen && updateModalOpen(false);
    updateImageModalOpen && updateImageModalOpen(false);
  };
  const handleBack = () => {
    updateImageModalOpen && updateImageModalOpen(false);
  };

  useEffect(() => {
    setInitialImageSrcList && setInitialImageSrcList('');
    if (imageId) {
      const handleImageBinary = async () => {
        const response = await getDoctorImage({ id: imageId });

        setInitialImageSrc && setInitialImageSrc(response.data);
        setInitialImageSrcList && setInitialImageSrcList(response.data);
      };

      handleImageBinary();
    }
  }, [imageId, setInitialImageSrc, setInitialImageSrcList, ImageModalOpen]);
  return (
    <Modal
      open={open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={720}
      styles={{ content: { borderRadius: '24px' } }}
      title={
        <div className="text-neutral90 text-base font-semibold flex flex-row gap-3">
          <button onClick={handleBack}>
            <BackIcon />
          </button>
          <div className="text-neutral90 text-xl font-semibold">
            View verification file for Dr. {name && name}
          </div>
        </div>
      }
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <img
          alt=""
          className="h-[70vh] object-cover"
          src={`${imageSrc ? imageSrc : initialImageSrc}`}
        />
      </div>
    </Modal>
  );
};

export default DoctorImageModal;
