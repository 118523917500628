import * as React from 'react';
import { SVGProps } from 'react';
const TickCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#1AB888"
      stroke="#1AB888"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M36.5 66c16.5 0 30-13.5 30-30S53 6 36.5 6s-30 13.5-30 30 13.5 30 30 30Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="m23.75 36 8.49 8.49 17.01-16.98"
    />
  </svg>
);

export default TickCircleIcon;
