import React, { useState } from 'react';
import { useShow } from '@refinedev/core';
import { Button, Table } from 'antd';
import Papa from 'papaparse';
import { DoctorRes } from '../../types';
import { ColHeaderLeft } from '@components/ColHeaderLeft';
import { LineOutlined } from '@ant-design/icons';
import { Show } from '@components/crud/showDoctor';
import ExtractUserDetailsModal from '@components/ExtractUserDetailsModal';
import { BackIcon, ExtractIcon } from '../../Assets/Svgs';
import { formatDate } from '../../Helpers/inedx';
import DoctorPointsManagementModal from '@components/PointsManagementModal';
import { EditIcon, WalletIcon } from '../../Assets/Svgs';
import DoctorInfoModal from '@components/DoctorInfoModal';
import DoctorImageModal from '@components/DoctorImageModal';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';
export const DoctorShow: React.FC = () => {
  const navigate = useNavigate();
  const [activeTable, setActiveTable] = useState('points');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { queryResult } = useShow<DoctorRes>();
  const { data, isLoading, refetch } = queryResult;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editDocModalOpen, setEditDocModalOpen] = useState<boolean>(false);
  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [initialImageSrc, setInitialImageSrc] = useState<string>('');

  const [accumulationFactor, setAccumulationFactor] = useState<number>(0.25);
  const [redemptionRate, setRedemptionRate] = useState<number | undefined>();
  const [editedFormData, setEditedFormData] = useState<{
    name: string | undefined;
    phone_number: string | undefined;
    email: any;
    imageSrc: any;
  }>({
    name: '',
    phone_number: '',
    email: '',
    imageSrc: '',
  });
  const record = data?.data;
  const navigateSharedWallet = (
    recordValue: DoctorRes | undefined,
    isLoading: boolean
  ) => {
    navigate('/doctors-list/shared-wallet', {
      state: { data: recordValue, isLoading: isLoading },
    });
  };
  const updateModalOpen = (value: boolean) => {
    setModalOpen(value);
  };
  const updateDocModalOpen = (value: boolean) => {
    setEditDocModalOpen(value);
  };
  const updateImageModalOpen = (value: boolean) => {
    setImageModalOpen(value);
  };
  const handleTableChange = (table: string) => {
    setActiveTable(table);
  };

  const exportToCSV = (value: string) => {
    const data =
      value === 'points'
        ? record?.rewarding_transactions
        : record?.redeemed_transactions;
    if (data) {
      const csv = Papa.unparse(data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute(
        'download',
        `${value === 'points' ? 'PointsHistory' : 'PurchasesHistory'}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return null;
  };
  const user_phone = record?.phone_number;

  const handleModalOpen = () => {
    setIsModalOpen(true);
    if (record) {
      if (accumulationFactor === 0.25) {
        setAccumulationFactor(record.factor_points);
      }
      if (!redemptionRate) {
        setRedemptionRate(record.redemption_rate);
      }
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Show
      isLoading={isLoading}
      goBack={null}
      title={
        <div className="flex gap-x-8">
          <Button
            onClick={() => navigate('/doctors-list')}
            icon={<BackIcon />}
            className="text-neutral70 font-semibold bg-transparent border-none shadow-none p-0"
          >
            Back
          </Button>
          <div className="text-neutral90 text-xl flex flex-row justify-between space-x-60">
            <div>Dr. {record?.name}</div>
          </div>
        </div>
      }
      headerButtons={
        <div className="flex gap-x-6 flex-wrap">
          <Button
            className="border-0 text-[#F09B29] font-semibold shadow-none"
            onClick={() => navigateSharedWallet(record, isLoading)}
          >
            Shared Wallet
            <WalletIcon />
          </Button>
          <Button
            className="border-0 text-[#F09B29] font-semibold shadow-none"
            onClick={handleModalOpen}
          >
            Points Management
          </Button>
          <Button
            className="border-0 text-[#F09B29] font-semibold shadow-none"
            onClick={() => {
              setEditedFormData({
                name: record?.name,
                phone_number: record?.phone_number,
                email: record?.email,
                imageSrc: initialImageSrc,
              });
              updateDocModalOpen(true);
            }}
          >
            Edit Doctor Info
            <EditIcon />
          </Button>
          <button
            onClick={() => updateModalOpen(true)}
            className="flex items-center gap-x-2 bg-primary text-white px-4 py-2 border rounded-[4px] shadow-sm cursor-pointer hover:bg-white hover:text-primary transition"
          >
            <div className="font-semibold">Extract</div>
            <ExtractIcon />
          </button>
        </div>
      }
    >
      <div className="mt-5 lg:mt-12">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => handleTableChange('points')}
            className="tabsBtn"
            style={{
              borderBottom:
                activeTable === 'points'
                  ? '2px solid #FF0006'
                  : '2px solid #E1E1E1',
              backgroundColor:
                activeTable === 'points' ? '#F8F8F8' : 'transparent',
              marginRight: '0px',
            }}
          >
            Points History
          </Button>
          <Button
            onClick={() => handleTableChange('purchases')}
            className="tabsBtn"
            style={{
              borderBottom:
                activeTable === 'purchases'
                  ? '2px solid #FF0006'
                  : '2px solid #E1E1E1',
              backgroundColor:
                activeTable === 'purchases' ? '#F8F8F8' : 'transparent',
            }}
          >
            Purchases History
          </Button>
        </div>
        {activeTable === 'purchases' ? (
          <Table
            dataSource={record?.redeemed_transactions}
            rowKey="id"
            className="w-full border border-gray-300 rounded-lg mt-4"
            bordered={false}
            pagination={{
              position: ['bottomCenter'],
              nextIcon: <></>,
              prevIcon: <></>,
            }}
          >
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Transaction ID" />}
              dataIndex="id"
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? value : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Mobile number" />}
              render={() => (
                <span className="text-neutral90 text-sm font-normal">
                  {user_phone ? user_phone : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Date" />}
              dataIndex={'trx_date'}
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? formatDate(value) : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Time" />}
              dataIndex={'time'}
              render={(value) => (
                <span> {value ? value : <LineOutlined />} </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Value (EGP)" />}
              dataIndex={'amount'}
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? commaNumber(value) : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Merchant" />}
              dataIndex={'merchant_name'}
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value}
                </span>
              )}
            />
          </Table>
        ) : (
          <Table
            dataSource={record?.rewarding_transactions}
            rowKey="id"
            className="w-full border border-gray-300 rounded-lg mt-4"
            bordered={false}
            pagination={{
              position: ['bottomCenter'],
              nextIcon: <></>,
              prevIcon: <></>,
            }}
          >
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Transaction ID" />}
              dataIndex="id"
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? value : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Type" />}
              dataIndex="epoints_type"
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? value : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Value (EGP)" />}
              dataIndex="points"
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? commaNumber(value / 100) : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Points" />}
              dataIndex={'points'}
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? commaNumber(value / 10) : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Date" />}
              dataIndex="trx_date"
              render={(value) => (
                <span className="text-neutral90 text-sm font-normal">
                  {value ? formatDate(value) : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderLeft title="Time" />}
              dataIndex="time"
              render={(value) => (
                <span className="text-neutral90 text-sm">
                  {value ? value : <LineOutlined />}
                </span>
              )}
            />
          </Table>
        )}
      </div>
      <DoctorPointsManagementModal
        open={isModalOpen}
        updateModalOpen={handleModalClose}
        doctor={record}
        accumulationFactor={accumulationFactor}
        setAccumulationFactor={setAccumulationFactor}
        redemptionRate={redemptionRate}
        setRedemptionRate={setRedemptionRate}
        refetch={refetch}
      />

      <ExtractUserDetailsModal
        open={modalOpen}
        updateModalOpen={updateModalOpen}
        id={record?.id}
        triggerExport={exportToCSV}
      />
      <DoctorInfoModal
        id={record?.id}
        updateModalOpen={updateDocModalOpen}
        updateImageModalOpen={updateImageModalOpen}
        open={editDocModalOpen}
        setImageSrc={setImageSrc}
        editedFormData={editedFormData}
        setEditedFormData={setEditedFormData}
        initialImageSrc={initialImageSrc}
        setInitialImageSrc={setInitialImageSrc}
        imageId={record?.user_profile?.upload?.id}
      />
      <DoctorImageModal
        id={record?.id}
        name={editedFormData.name}
        updateModalOpen={updateDocModalOpen}
        updateImageModalOpen={updateImageModalOpen}
        open={imageModalOpen}
        imageSrc={imageSrc}
        initialImageSrc={initialImageSrc}
        setInitialImageSrc={setInitialImageSrc}
        imageId={record?.user_profile?.upload?.id}
      />
    </Show>
  );
};
