import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import './styles.css';
import { Datum } from '../../types';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  graphData: Datum[];
}

const PointsLineChart: React.FC<Props> = (props) => {
  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      {props.graphData ? (
        <>
          <LineChart
            width={1050}
            height={347}
            data={props.graphData}
            margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
          >
            <Legend
              aria-label="points"
              verticalAlign="top"
              iconType="circle"
              iconSize={8}
              display={'flex'}
            />
            <CartesianGrid
              vertical={false}
              horizontal={true}
              strokeDasharray=""
              color="#f2f4f7"
            />
            <XAxis
              dataKey="monthYear"
              label={{ value: 'Month', position: 'bottom' }}
              className="custom-x-axis"
              axisLine={{ strokeWidth: 10 }}
              tickMargin={10}
              style={{ marginRight: '100px' }}
            />
            <YAxis
              label={{ value: 'Points', angle: -90, position: 'insideLeft' }}
              className="custom-x-axis"
              tickFormatter={(value) =>
                value >= 1000 ? `${value / 1000}k` : value
              }
            />

            <Tooltip />
            <Line
              type="natural"
              dataKey="excellence"
              stroke={'#F1C244'}
              dot={false}
            />
            <Line
              type="natural"
              dataKey="firstClass"
              stroke={'#C01E2E'}
              dot={false}
            />
          </LineChart>
        </>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  );
};

export default PointsLineChart;
