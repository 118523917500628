import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { CloseIcon } from '../../Assets/Svgs';
import { addWalletMember } from '../../Actions/ConfigActions';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  account_id: number;
  refetch: () => void;
}
interface Result {
  status: number;
  response: {
    data: {
      message: string;
    };
  };
}
const AddMemberModal: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleOk = async (value: { name: string; phone: string }) => {
    if (!value.name || !value.phone) {
      setErrorMessage('Please fill all fields');
    }
    const result = (await addWalletMember({
      name: value.name,
      phone: value.phone,
      account_id: props.account_id,
      setLoading,
    })) as Result;
    form.resetFields();
    if (result.status === 201) {
      props.updateModalOpen(false);
      props.refetch();
    } else {
      setErrorMessage(result.response.data.message);
    }
  };

  const handleCancel = () => {
    props.updateModalOpen(false);
  };
  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={498}
      styles={{ content: { borderRadius: '24px' } }}
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <div className="text-gray500">
          Add new member to shared wallet (Max: 4 members)
        </div>
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        <Form form={form} className="w-full" onFinish={handleOk}>
          <Form.Item
            name={'name'}
            rules={[
              { required: true, message: 'Please enter member name' },
              { type: 'string' },
              { pattern: /^[A-Za-z\s]+$/, message: 'Please enter valid name' },
            ]}
          >
            <Input
              placeholder={'Member Name'}
              className="bg-[#F5F5F5] border-[#C2C2C2] border rounded shadow-none py-3 px-4"
            />
          </Form.Item>
          <Form.Item
            name={'phone'}
            rules={[
              { required: true, message: 'Please enter mobile number' },
              { type: 'string' },
              { pattern: /^01[0-9]{9}$/, message: 'Please enter valid number' },
            ]}
          >
            <Input
              placeholder="Mobile Number"
              className="bg-[#F5F5F5] border-[#C2C2C2] border rounded shadow-none py-3 px-4"
            />
          </Form.Item>
          <Button
            size="large"
            type="primary"
            className="w-full rounded-[12px] text-white bg-elmokhtabarPrimary h-14"
            htmlType="submit"
            loading={loading}
          >
            Add
          </Button>
        </Form>
        <Button
          className="text-[#848484] hover:cursor-pointer bg-inherit border-none shadow-none"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
