import React from 'react';
import { ExtractIcon, ImportIcon } from '../../Assets/Svgs';
import { Loading3QuartersOutlined } from '@ant-design/icons';

interface BulkPointsManagementProps {
  title: string;
  showImportButton?: boolean;
  showExtractButton?: boolean;
  onImportClick: (value: boolean) => void;
  onExtractClick?: () => void;
  exportLoading?: boolean;
}

const BulkPointsManagement: React.FC<BulkPointsManagementProps> = ({
  showImportButton = true,
  showExtractButton = true,
  onExtractClick,
  exportLoading,
  onImportClick,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      {showImportButton && (
        <button
          className='className="flex items-center gap-x-2 border-primary px-4 py-2 text-primary  border rounded-[4px] shadow-sm cursor-pointer hover:bg-primary hover:text-white transition'
          onClick={() => onImportClick(true)}
        >
          <div className="flex items-center">
            <div className="font-semibold">Import</div>
            <ImportIcon />
          </div>
        </button>
      )}
      {showExtractButton && (
        <button
          onClick={onExtractClick}
          className="flex items-center gap-x-2 bg-primary text-white px-4 py-2 border rounded-[4px] shadow-sm cursor-pointer hover:bg-white hover:text-primary transition"
        >
          {exportLoading ? (
            <div>
              <Loading3QuartersOutlined spin />
            </div>
          ) : (
            <>
              <div className="font-semibold">Extract</div>
              <ExtractIcon />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default BulkPointsManagement;
