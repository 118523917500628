import React, { useEffect, useState } from 'react';
// import { convertToArabicNumbers } from '../../Helpers';
import { LoadingOutlined } from '@ant-design/icons';
import PointsLineChart from '@components/PointsLineChart';
import { DashBoardCard } from '@components/DashBoardCard';
import { DatePickerPopUp } from '@components/DatePicker';
import { HomeData } from 'src/types';
import { GetGraphDataAction } from '../../Actions/ConfigActions';
import commaNumber from 'comma-number';

export const DashboardPage = () => {
  const [selectedDates, setSelectedDates] = useState<{
    fromDate: string | null;
    toDate: string | null;
  }>({
    fromDate: null,
    toDate: null,
  });
  const [graphData, setGraphData] = useState<HomeData | null>(null);
  const handleDateChange = (dates: Date[]) => {
    setSelectedDates({
      fromDate: dates[0].toISOString().slice(0, 10),
      toDate: dates[1].toISOString().slice(0, 10),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedDates.fromDate && selectedDates.toDate) {
          const data = await GetGraphDataAction(
            selectedDates.fromDate,
            selectedDates.toDate
          );
          setGraphData(data as HomeData); // Type assertion
        } else {
          const data = await GetGraphDataAction();
          setGraphData(data as HomeData); // Type assertion
        }
      } catch (error) {
        return error;
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup code if necessary
    };
  }, [selectedDates]);
  return (
    <div className="flex lg:justify-center flex-col gap-y-12">
      <div className="flex flex-col gap-y-4 w-full">
        <div className="flex flex-col lg:flex-row items-center justify-between w-full">
          <div className="flex flex-col lg:flex-row w-fit gap-x-6 gap-y-2">
            <div className="text-[24px] text-neutral90 font-semibold">
              Points Earned
            </div>
            <div className="flex items-center gap-x-1 text-[24px] font-semibold text-primary">
              {graphData ? (
                <>
                  {graphData?.totalFirstClassEarnedPoints !== undefined &&
                  graphData.totalFirstClassEarnedPoints !== null
                    ? commaNumber(graphData?.totalFirstClassEarnedPoints)
                    : 0}
                </>
              ) : (
                <LoadingOutlined />
              )}
              <div className="text-sm"> PTS/First Class</div>
            </div>
            <div className="flex items-center gap-x-1 text-[24px] font-semibold text-elborgPrimary">
              {graphData ? (
                <>
                  {graphData?.totalExcellenceEarnedPoints !== undefined &&
                  graphData.totalExcellenceEarnedPoints !== null
                    ? commaNumber(graphData?.totalExcellenceEarnedPoints)
                    : 0}
                </>
              ) : (
                <LoadingOutlined />
              )}
              <div className="text-sm"> PTS/Excellence</div>
            </div>
          </div>
          <div>
            <DatePickerPopUp onDateChange={handleDateChange} />
          </div>
        </div>
        <div className="overflow-x-scroll lg:overflow-auto">
          {graphData !== null && (
            // check on this
            <PointsLineChart graphData={graphData?.graphData || []} />
          )}
        </div>
      </div>
      <div className="flex items-center flex-col lg:flex-row gap-12 w-full flex-no-wrap self-stretch">
        <DashBoardCard
          value={
            graphData ? (
              graphData?.doctor_count !== undefined &&
              graphData?.doctor_count !== null ? (
                `${graphData?.doctor_count}`
              ) : (
                0
              )
            ) : (
              <LoadingOutlined />
            )
          }
          title="Doctor"
        />
        <DashBoardCard
          value={
            graphData ? (
              graphData?.totalTransaction !== undefined &&
              graphData?.totalTransaction !== null ? (
                `${graphData?.totalTransaction}`
              ) : (
                0
              )
            ) : (
              <LoadingOutlined />
            )
          }
          title="Transactions"
        />
        <DashBoardCard
          value={
            graphData ? (
              graphData?.redemptionRate !== undefined &&
              graphData?.redemptionRate !== null ? (
                `${graphData?.redemptionRate.replace('%', '')}`
              ) : (
                0
              )
            ) : (
              <LoadingOutlined />
            )
          }
          percent
          title="Redemption Rate"
        />
      </div>
    </div>
  );
};
