import { Button, Modal } from 'antd';
import React from 'react';
import { CloseIcon } from '../../Assets/Svgs';
import { useDelete } from '@refinedev/core';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  id: number;
  title?: string;
  header?: string;
  memberDelete?: boolean;
  refetch: () => void;
}

const DeleteDoctortModal: React.FC<Props> = (props: Props) => {
  const { mutate } = useDelete();
  const handleOk = () => {
    if (props.memberDelete) {
      mutate({
        resource: `shared-wallet`,
        id: props.id,
        invalidates: ['list'],
        successNotification: () => {
          props.refetch();
          return {
            message: `Records have successfully deleted.`,
            type: 'success',
          };
        },
        errorNotification: () => {
          return {
            message: `Something went wrong.`,
            type: 'error',
          };
        },
      });
      props.updateModalOpen(false);
    } else {
      props.updateModalOpen(false);
      mutate({
        resource: 'account-cruds/delete-account',
        id: props.id,
        invalidates: ['list'],
        successNotification: () => {
          return {
            message: `Records have successfully deleted.`,
            type: 'success',
          };
        },
        errorNotification: () => {
          return {
            message: `Something went wrong.`,
            type: 'error',
          };
        },
      });
      props.refetch();
      props.updateModalOpen(false);
    }
  };

  const handleCancel = () => {
    props.updateModalOpen(false);
  };
  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={498}
      styles={{ content: { borderRadius: '24px' } }}
      title={
        <div className="text-neutral90 text-base font-semibold">
          {props.title ? props.title : 'Delete Doctor?'}
        </div>
      }
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <div className="text-gray500">
          {props.header
            ? props.header
            : 'Are you sure you want to delete this doctor?'}
        </div>

        <Button
          size="large"
          type="primary"
          className="w-full rounded-[12px] text-white bg-elmokhtabarPrimary h-14"
          htmlType="submit"
          onClick={handleOk}
        >
          Delete
        </Button>
        <Button
          className="text-[#848484] hover:cursor-pointer bg-inherit border-none shadow-none"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteDoctortModal;
